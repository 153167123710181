import React from "react"
import ThemeChanger from "../components/themeChanger"

export default (props) => (
  <nav className="navigation">
    {/* <Link to="/contact">Contact</Link> */}
    <a href='https://twitter.com/intent/follow?ref_src=twsrc%5Etfw&screen_name=nicam&tw_p=followbutton' rel="noreferrer" target="_blank" aria-label="Twitter" title="Subscribe on twitter to get informed about new blogposts"><span className="mobile-hidden twitter-text">Subscribe on </span><i className="gg-twitter"></i></a>
    <a href='/rss.xml' title="RSS Feed" target="_blank" className="feed-box" aria-label="RSS feed"><i className="gg-feed"></i></a>
    <ThemeChanger/>
  </nav>

)